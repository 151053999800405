(function ($) {
	$(window).load(function () {
		// toggle homepage banner on mobile
		// if (device.mobile()) {
		// 	$(".home .eyecatcher .vimeo_video").remove();
		// } else {
		// 	$(".home .eyecatcher .image_collection").remove();
		// }

		// owl-carousel
		$(".accommodation-carousel").owlCarousel({
			// slider settings
			autoplay: true,
			autoplayHoverPause: true,
			loop: true,
			items: 1,

			// slide speed
			autoplayTimeout: 7000,
			smartSpeed: 500,

			// animation
			animateIn: "fadeIn",
			animateOut: "fadeOut",

			// navigation
			nav: true,
			navText: [
				'<i class="far fa-chevron-circle-left"></i>',
				'<i class="far fa-chevron-circle-right"></i>',
			],

			dots: false,

			// drag options
			mouseDrag: true,
			lazyLoad: true,
		});

		// owl-carousel
		$(".owl-carousel:not(.slider):not(.accommodation-carousel)").owlCarousel({
			// slider settings
			autoplay: true,
			autoplayHoverPause: true,
			loop: true,
			items: 1,

			// slide speed
			autoplayTimeout: 7000,
			smartSpeed: 500,

			// animation
			animateIn: "fadeIn",
			animateOut: "fadeOut",

			// navigation
			nav: true,
			navText: [
				'<i class="far fa-chevron-circle-left"></i>',
				'<i class="far fa-chevron-circle-right"></i>',
			],

			dots: true,

			// drag options
			mouseDrag: false,
		});

		// slider
		$(".slider:not(.slider-4-items)").owlCarousel({
			// slider settings
			autoplay: false,
			autoplayHoverPause: true,
			loop: true,
			items: 1,
			margin: 15,

			// slide speed
			autoplayTimeout: 7000,
			smartSpeed: 500,

			// navigation
			nav: false,
			dots: true,

			// drag options
			mouseDrag: true,

			// responsive
			responsive: {
				0: {
					items: 1,
				},
				576: {
					items: 2,
				},
				768: {
					items: 2,
				},
				992: {
					items: 3,
				},
				1200: {
					items: 3,
					margin: 45,
				},
			},
		});

		// slider 4 items
		$(".slider-4-items").owlCarousel({
			// slider settings
			autoplay: false,
			autoplayHoverPause: true,
			loop: true,
			items: 1,
			margin: 15,

			// slide speed
			autoplayTimeout: 7000,
			smartSpeed: 500,

			// navigation
			nav: false,
			dots: true,

			// drag options
			mouseDrag: true,

			// responsive
			responsive: {
				0: {
					items: 1,
				},
				576: {
					items: 2,
				},
				768: {
					items: 2,
				},
				992: {
					items: 3,
					margin: 30,
				},
				1200: {
					items: 4,
					margin: 30,
				},
			},
		});
	});

	$(document).ready(function () {
		$(".search-icon").click(function () {
			$(this).parent().find(".form-control").toggleClass("show");
			$(this).toggleClass("hide");
		});

		var windowWidth = $(window).width();
		if (windowWidth > 1200) {
			$(window).scroll(function () {
				if ($(window).scrollTop() > 0) {
					$("body").addClass("sticky");
					$(".header").addClass("sticky");
				} else {
					$("body").removeClass("sticky");
					$(".header").removeClass("sticky");
				}
			});
		}

		$("a[data-fancybox]").fancybox({
			buttons: [
				//"zoom",
				//"share",
				//"slideShow",
				//"fullScreen",
				//"download",
				"thumbs",
				"close",
			],
		});

		// accomodation read more btn
		$('#acco-read-more').on('click', function(e) {
			$(this).parent('.read-more').toggleClass('active');
			$(this).find('.more').toggleClass('d-none');
			$(this).find('.less').toggleClass('d-none');
			$('.accommodation_detail').find('.description').toggleClass('active');
		});
	});
})(jQuery);

// faq fix
let faqlinks = document.querySelectorAll(".faq-category a");
faqlinks.forEach((link) => {
	if (link.id) {
		link.id = "";
		link.href = "#";
	}
});
